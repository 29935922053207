import {
  memo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Row, Col, Table, Form, Button, } from 'react-bootstrap';
import {
  PermissionsEnum,
} from '../../utils/enum';

import Card from '../../components/bootstrap/card';
import { useSortableData } from '../../utils/hooks/useSortableData';
import { useSelection } from '../../utils/hooks/useSelection';
import DynamicPagination from '../../components/table/DynamicPagination';
import { ApiClient } from '../../services/ApiClient';
import SkeletonRow from '../../components/table/skeletonRow/SkeletonRow';
import PaginationInfo from '../../components/table/PaginationInfo';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ComboButtonGroup, {
  ComboButtonId,
} from '../../components/ComboButtonGroup';
import { Focus } from '../../interfaces';
import NotificationToast from '../../components/NotificationToast';
import { useToast } from '../../services/context/ToastContext';
import { usePermissions } from '../../utils/hooks/usePermissions';
import SearchInput from '../../components/SearchInput';
import AddEditFocusModal from '../../components/focuses/modal/AddEditFocusModal';
import useResetUrlParams from '../../utils/hooks/useResetUrlParams';
import GeneralSelectionActions from '../../components/GeneralSelectionActions';

export const mockFocuses: Focus[] = [
  {
    id: 1,
    name: "frontend",
    title: "Frontend Development"
  },
  {
    id: 2,
    name: "backend",
    title: "Backend Development"
  },
  {
    id: 3,
    name: "fullstack",
    title: "Full Stack Development"
  },
  {
    id: 4,
    name: "devops",
    title: "DevOps Engineering"
  },
  {
    id: 5,
    name: "datascience",
    title: "Data Science"
  },
  {
    id: 6,
    name: "machinelearning",
    title: "Machine Learning"
  },
  {
    id: 7,
    name: "cybersecurity",
    title: "Cybersecurity"
  },
  {
    id: 8,
    name: "cloudcomputing",
    title: "Cloud Computing"
  },
  {
    id: 9,
    name: "mobiledevelopment",
    title: "Mobile Development"
  },
  {
    id: 10,
    name: "gamedevelopment",
    title: "Game Development"
  }
];


interface FocusesResponse {
  page: number;
  itemsPerPage: number;
  amountPages: number;
  amountAllItems: number;
  list: Focus[];
  searchFilters: string[];
}

const comboButtons = [
  { id: 'all', label: 'Alle' },
];

const Focuses = memo(() => {
  const navigate = useNavigate();
  const resetUrlParams = useResetUrlParams();
  const { companyId = 'oc' } = useParams();
  const { show, message, error, showToast, hideToast } = useToast();
  const [selectedCombo, setSelectedCombo] = useState<ComboButtonId | ''>('all');
  const [selectedSearchFilter, setSelectedSearchFilter] = useState<ComboButtonId | ''>('all');
  const [availableFilter, setAvailableFilter] = useState<string[]>([]);
  const [focuses, setFocuses] = useState<Focus[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [limit, setLimit] = useState<number>(25);
  const [totalEntries, setTotalEntries] = useState<number>(200);
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [resetSearchInput, setResetSearchInput] = useState<boolean>(false);
  const { userHasPermissionByRight, permissionsLoaded } = usePermissions();
  const { items: sortedFocuses, requestSort } = useSortableData(focuses);
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const getId = useCallback((focus: Focus) => focus.id, []);

  const {
    selectedItems: selectedFocuses,
    isAllSelected,
    selectedCount,
    handleSelectAll,
    handleDeSelectAll,
    handleSelectRow,
  } = useSelection(focuses, getId);

  const handleSearch = (data: { query: string, filter: string | undefined }) => {
    if (data.query) {
      setSearchQuery(data.query);
      setSelectedSearchFilter(data.filter || '');
      setCurrentPage(1);
    } else if (searchQuery) {
      resetSearch();
    }
  };

  const fetchFocuses = useCallback(async () => {
    setFocuses([]);
    setIsLoading(true);
    let queryParams = `?page=${currentPage}`;

    if (selectedCombo !== 'all') {
      queryParams += `&status=${selectedCombo}`;

    } else {
      if (limit.toString()) {
        queryParams += `&limit=${limit}`;
      }
      if (searchQuery) {
        queryParams += `&search=${encodeURIComponent(searchQuery)}`;
        if (selectedSearchFilter !== 'all') {
          queryParams += `&column=${encodeURIComponent(selectedSearchFilter)}`
        }
      }
    }

    try {
      // const response = await ApiClient.get(`/focuses${queryParams}`);
      // const FocusesResponse = response.data as FocusesResponse;
      // setTotalPages(FocusesResponse.amountPages);
      // setFocuses(FocusesResponse.list);
      setFocuses(mockFocuses);
      // setCurrentPage(FocusesResponse.page);
      // setLimit(FocusesResponse.itemsPerPage);
      // setTotalEntries(FocusesResponse.amountAllItems);
      //setAvailableFilter(FocusesResponse.searchFilters)
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [currentPage, selectedCombo, searchQuery, limit]);


  useEffect(() => {
    if (permissionsLoaded) {
      const hasPermission = userHasPermissionByRight(PermissionsEnum.Focuses, 'read');

      if (hasPermission) {
        fetchFocuses();
      } else {
        navigate('/errors/error404');
      }
    }
  }, [
    currentPage,
    searchQuery,
    selectedCombo,
    fetchFocuses,
    permissionsLoaded,
    limit
  ]);


  const handleFocusUpdateSubmit = () => {
    fetchFocuses();
  };

  const handleComboSelect = (newCombo: ComboButtonId) => {
    setSelectedCombo(newCombo);
    setCurrentPage(1);
  };

  const resetSearch = () => {
    resetUrlParams()
    setSearchQuery('');
    setResetSearchInput(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (resetSearchInput) {
      setResetSearchInput(false);
    }
  }, [resetSearchInput]);

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <h3>Schwerpunkte</h3>

        <Button disabled={!userHasPermissionByRight(PermissionsEnum.Focuses, 'write')} variant="primary" onClick={() => setShowAddEditModal(true)}>
          Schwerpunkt hinzufügen
        </Button>
      </div>
      <Card className="card-block card-stretch card-height">
        <Card.Body>
          <Row className="d-flex justify-content-between mb-4">
            <Col md={6}>
              {searchQuery ?
                <div className="d-flex align-items-baseline">
                  <h4 className="m-0">Suchergebnisse</h4>
                  <span className="ms-3 d-flex align-items-baseline">
                    <Button
                      className="m-0 p-0 fs-6"
                      variant="link"
                      onClick={resetSearch}
                    >
                      Suche beenden
                    </Button>
                  </span>
                </div>

                : <ComboButtonGroup
                  buttons={comboButtons}
                  selectedCombo={selectedCombo}
                  setSelectedCombo={handleComboSelect}
                  borderRadius="normal"
                ></ComboButtonGroup>}
            </Col>
            <Col md={3}>
              <SearchInput hasFilters onSearch={handleSearch} dropdownItems={availableFilter} reset={resetSearchInput} />
            </Col>
          </Row>
          <Row>
            <Col>

            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th
                className="d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                scope="col"
                onClick={() => requestSort('title')}
              >
                <Form.Check
                  disabled={!userHasPermissionByRight(PermissionsEnum.Focuses, 'write') && !userHasPermissionByRight(PermissionsEnum.Focuses, 'delete')}
                  className="me-3"
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
                Titel
              </th>
              <th
                style={{ cursor: 'pointer', width: '80px' }}
                scope="col"
                onClick={() => requestSort('id')}
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCount > 0 && (
              <GeneralSelectionActions
                selectedItems={selectedFocuses}
                selectedCount={selectedCount}
                handleDeSelectAll={handleDeSelectAll}
                onSubmitSuccess={handleFocusUpdateSubmit}
                amountAllItems={totalEntries}
                entityType='focuses'
              ></GeneralSelectionActions>
            )}
            {isLoading
              ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonRow key={index} columnCount={5} />
              ))
              : sortedFocuses.map((focus) => (
                <tr key={focus.id} className="bg-white">
                  <td className="d-flex align-items-center">
                    <Form.Check
                      disabled={!userHasPermissionByRight(PermissionsEnum.Focuses, 'write') && !userHasPermissionByRight(PermissionsEnum.Focuses, 'delete')}
                      className="me-3"
                      type="checkbox"
                      checked={
                        selectedFocuses[focus.id] ?? false
                      }
                      onChange={() => { }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectRow(focus.id, e)
                      }}
                    />
                    <Link
                      to={`/${companyId}/focuses/${focus.id}`}
                      className="btn btn-link ps-0 text-start"
                      style={{ overflowWrap: 'break-word', flex: 1 }}
                    >
                      {focus.title}
                    </Link>
                  </td>
                  <td>{focus.id}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {!isLoading && sortedFocuses.length === 0 && (
          <div
            className="d-flex justify-content-center align-items-center border rounded my-3"
            style={{ height: '50px' }}
          >
            <p className="p-0 m-0">Keine Schwerpunkte gefunden</p>
          </div>
        )}
      </div>

      {totalEntries > 0 && (
        <Row>
          <Col>
            <PaginationInfo
              currentPage={currentPage}
              limit={limit}
              totalEntries={totalEntries}
              onLimitChange={(size) => {
                setLimit(size);
                setCurrentPage(1);
              }}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <DynamicPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Col>
        </Row>
      )}

      {showAddEditModal && <AddEditFocusModal
        modalTitle={`Schwerpunkt hinzufügen`}
        onSubmitSuccess={handleFocusUpdateSubmit}
        onModalClose={() => setShowAddEditModal(false)}
      >
      </AddEditFocusModal>
      }

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </Fragment>
  );
});

export default Focuses;

import React from 'react';
import { ButtonGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faThumbtack, faComment } from '@fortawesome/pro-regular-svg-icons';

interface ActionButtonsProps {
    activityId: number;
    isPinned: boolean;
    isArchived: boolean;
    onActionClick: (activityId: number, action: 'pin' | 'archive' | 'comment') => void;
}

/**
 * ActionButtons Component
 * 
 * This component renders a group of action buttons for each activity, allowing users to comment, pin/unpin, 
 * or archive/unarchive the activity. Each button has an icon with a tooltip, and the pin/archive buttons 
 * visually indicate their active status.
 * 
 * Props:
 * - `activityId`: The unique identifier for the activity associated with these action buttons.
 * - `isPinned`: A boolean indicating if the activity is currently pinned. When true, the pin button appears active.
 * - `isArchived`: A boolean indicating if the activity is currently archived. When true, the archive button appears active.
 * - `onActionClick`: A callback function triggered when any of the action buttons is clicked. It receives the 
 *   `activityId` and the action type ('pin', 'archive', or 'comment') as arguments.
 * 
 * Example Usage:
 * ```tsx
 * <ActionButtons
 *   activityId={1}
 *   isPinned={true}
 *   isArchived={false}
 *   onActionClick={(id, action) => console.log(`Activity ${id} - Action: ${action}`)}
 * />
 * ```
 */

const ActionButtons: React.FC<ActionButtonsProps> = ({ activityId, isPinned, isArchived, onActionClick }) => {
    return (
        <ButtonGroup size="sm">
            <OverlayTrigger placement="top" overlay={<Tooltip>Kommentieren <br></br> (Funktion kommt noch)</Tooltip>}>
                <label className='btn me-2' onClick={() => onActionClick(activityId, 'comment')}>
                    <FontAwesomeIcon icon={faComment} />
                </label>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>{isPinned ? 'Unpinnen' : 'Pinnen'}</Tooltip>}>
                <label
                    className={`me-2 ${isPinned ? 'btn btn-soft-primary' : 'btn'}`}
                    onClick={() => onActionClick(activityId, 'pin')}
                >
                    <FontAwesomeIcon icon={faThumbtack} />
                </label>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>{isArchived ? 'Unarchivieren' : 'Archivieren'}</Tooltip>}>
                <label
                    className={`${isArchived ? 'btn btn-soft-primary' : 'btn'}`}
                    onClick={() => onActionClick(activityId, 'archive')}
                >
                    <FontAwesomeIcon icon={faArchive} />
                </label>
            </OverlayTrigger>
        </ButtonGroup>
    );
};

export default ActionButtons;

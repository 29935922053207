import React, { FormEvent, useMemo, useRef, useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Spinner,
} from 'react-bootstrap';
import FormGroup from '../../form/FormGroup';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import ComboButtonGroup, { ComboButton, ComboButtonId } from '../../ComboButtonGroup';
import { ApiClient } from '../../../services/ApiClient';
import {
  ActiveInactiveMapping,
  Countries,
  GermanStates,
  getEnumValue,
  PermissionsEnum,
} from '../../../utils/enum';
import { AccountId, FieldConfig, Location, MediaItem, MeinNowAccounts, Partner, PartnerNames, PaymentMethods, RentIncrease, Setting } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import MediaPickerModal from '../../media/modal/MediaPickerModal';
import DeleteModal from '../../DeleteModal';
import { faFileContract, faHouse, faMoneyBill, faMoneyCheckDollarPen, faXmark } from '@fortawesome/pro-regular-svg-icons';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import ArchiveModal from '../../ArchiveModal';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import DropdownWithAddOption from '../../DropdownWithAddOption';
import { getFieldOptions, sanitizeInput } from '../../../utils/utils';
import AddressAutocomplete from '../../AddressAutocomplete';
import Card from '../../bootstrap/card';
import SelectedMediaPreview from '../../media/SelectMediaPreview';
import SelectGroup from '../../form/SelectGroup';
import SwitchGroup from '../../form/SwitchGroup';
import RentIncreaseList from './RentIncreaseList';

type AddEditLocationModalProps = {
  modalTitle: string;
  location?: Location;
  onSubmitSuccess: (locationsId: number) => void;
  onAccountsUpdate: () => void;
  onPartnerUpdate: () => void;
  onPaymentMethodsUpdate: () => void;
  onModalClose: () => void;
  accounts?: AccountId
  partner?: Partner
  paymentMethods?: PaymentMethods
  fieldConfigs?: FieldConfig[]
};

interface FormValues {
  title: string;
  name: string;
  accountId: string;
  city: string;
  country: string;
  email: string;
  fax: string;
  googleBusiness: string;
  zipcode: string;
  mobile: string;
  partner: string;
  state: string;
  status: number;
  statusCertification: number;
  street: string;
  streetNr: string;
  telephone: string;
  contractDateOfTermination: string;
  contractEnd: string;
  contractMinRentalPeriod: number;
  contractNoticePeriod: number;
  contractRentalStatus: number
  contractStart: string;
  costsDeposit: string
  costsFurtherRents: number;
  costsMonthlyGross: number;
  costsMonthlyNet: string
  costsOfTermination: string
  costsOneTime: string
  costsPaymentFirstRent: string;
  costsPaymentMethod: string;
  costsRentIncrease: string;
  costsTax: number;
  costsTaxDuty: boolean
  landlordContactPerson: string;
  landlordEmail: string;
  landlordIban: string;
  landlordIntendedUse: string;
  landlordName: string;
  utilization: number;
  officeType: number
  maxParticipants: number
  area: string
  contractAutoRenewal: boolean
  keyType: number
  rescuePlan: boolean
  fireExtinguisher: boolean
  escapeRouteSigns: boolean
  firstAidKit: boolean
  standingOrder: boolean
  paymentTime: number
  paymentDocumentType: number
  dateOfDeposit: string
  terminationTaxDuty: boolean
  statusContract: number
  rentCostIncreases: RentIncrease[]
  notesDeposit: string
  notesPayment: string
}

const AddEditLocationModal: React.FC<AddEditLocationModalProps> = ({
  modalTitle,
  location,
  onSubmitSuccess,
  onAccountsUpdate,
  onPartnerUpdate,
  onPaymentMethodsUpdate,
  onModalClose,
  accounts,
  partner,
  paymentMethods,
  fieldConfigs
}) => {
  const { show, message, error, showToast, hideToast } = useToast();
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const { locationId } = useParams<{ locationId?: string }>();
  const companyNavigate = useCompanyNavigate()
  const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
  const { userHasPermissionByRight } = usePermissions();
  const [showStreetNr, setShowStreetNr] = useState(false);
  const [showCity, setShowCity] = useState(false);
  const [isMissingAddress, setIsMissingAddress] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ComboButtonId | ''>('location_edit_overview');
  const [showMainImageSelectionModal, setShowMainImageSelectionModal] = useState(false);
  const [formValues, setFormValues] = useState({
    title: location?.title ?? '',
    name: location?.name ?? '',
    accountId: location?.accountId ?? '',
    city: location?.city ?? '',
    country: location?.country ?? '',
    email: location?.email ?? '',
    fax: location?.fax ?? '',
    googleBusiness: location?.googleBusiness ?? '',
    googleLat: location?.googleLat ?? '',
    googleLong: location?.googleLong ?? '',
    hubspotId: location?.hubspotId ?? '',
    lidIdent: location?.lidIdent ?? '',
    mobile: location?.mobile ?? '',
    partner: location?.partner ?? '',
    state: location?.state ?? '',
    status: location?.status ?? 2,
    statusCertification: location?.statusCertification ?? 2,
    statusKursnet: location?.statusKursnet ?? '',
    street: location?.street ?? '',
    streetNewplan: location?.streetNewplan ?? '',
    streetNr: location?.streetNr ?? '',
    telephone: location?.telephone ?? '',
    titleNewplan: location?.titleNewplan ?? '',
    zipcode: location?.zipcode ?? '',
    contractMedia: location?.contractMedia || [],
    imageMedia: location?.imageMedia || [],
    mainImage_id: location?.mainImage_id ?? null,
    mainImage: location?.mainImage,
    contractDateOfTermination: location?.contractDateOfTermination ?? '0000-00-00 00:00:00',
    contractEnd: location?.contractEnd ?? '0000-00-00 00:00:00',
    contractMinRentalPeriod: location?.contractMinRentalPeriod ?? 0,
    contractNoticePeriod: location?.contractNoticePeriod ?? 0,
    contractRentalStatus: location?.contractRentalStatus ?? 0,
    contractStart: location?.contractStart ?? '0000-00-00 00:00:00',
    costsDeposit: location?.costsDeposit ? location?.costsDeposit?.toString().replace(/\./g, ',') : "",
    costsFurtherRents: location?.costsFurtherRents ?? 0,
    costsMonthlyGross: location?.costsMonthlyGross ?? 0,
    costsMonthlyNet: location?.costsMonthlyNet ? location?.costsMonthlyNet?.toString().replace(/\./g, ',') : "",
    costsOfTermination: location?.costsOfTermination ? location?.costsOfTermination?.toString().replace(/\./g, ',') : "",
    costsOneTime: location?.costsOneTime ? location?.costsOneTime?.toString().replace(/\./g, ',') : "",
    costsPaymentFirstRent: location?.costsPaymentFirstRent ?? '0000-00-00 00:00:00',
    costsPaymentMethod: location?.costsPaymentMethod ?? '',
    costsRentIncrease: location?.costsRentIncrease ?? '',
    costsTax: location?.costsTax ?? 0,
    costsTaxDuty: Boolean(location?.costsTaxDuty) ?? false,
    landlordContactPerson: location?.landlordContactPerson ?? '',
    landlordEmail: location?.landlordEmail ?? '',
    landlordIban: location?.landlordIban ?? '',
    landlordIntendedUse: location?.landlordIntendedUse ?? '',
    landlordName: location?.landlordName ?? '',
    utilization: location?.utilization ?? 0,
    certificates_id: location?.certificates_id ?? null,
    certificate: location?.certificate,
    officeType: location?.officeType ?? 0,
    maxParticipants: location?.maxParticipants ?? 0,
    area: location?.area ?? '',
    contractAutoRenewal: location?.contractAutoRenewal ?? false,
    keyType: location?.keyType ?? 0,
    rescuePlan: location?.rescuePlan ?? false,
    fireExtinguisher: location?.fireExtinguisher ?? false,
    escapeRouteSigns: location?.escapeRouteSigns ?? false,
    firstAidKit: location?.firstAidKit ?? false,
    standingOrder: location?.standingOrder ?? false,
    paymentTime: location?.paymentTime ?? 0,
    paymentDocumentType: location?.paymentDocumentType ?? 0,
    dateOfDeposit: location?.dateOfDeposit ?? '0000-00-00 00:00:00',
    terminationTaxDuty: location?.terminationTaxDuty ?? false,
    statusContract: location?.statusContract ?? 0,
    rentCostIncreases: location?.rentCostIncreases ?? [],
    notesDeposit: location?.notesDeposit ?? '',
    notesPayment: location?.notesPayment ?? ''
  });
  const [initialFormValues, setInitialFormValues] = useState<FormValues>({
    ...formValues,
  });

  const { street, streetNr, zipcode, city, state, country } = formValues;
  const isAddressComplete = street && zipcode && streetNr && city && state && country;

  const checkIfDataChanged = (): boolean => {
    return JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!isAddressComplete) {
      setIsMissingAddress(true)
      return
    } else {
      setIsMissingAddress(false)
    }

    if (!form.checkValidity()) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsLoading(true);
      try {
        let response;
        if (locationId) {
          response = await ApiClient.put(
            `/locations/${locationId}`,
            transformFormValues(formValues)
          );
        } else {
          response = await ApiClient.post('/locations', transformFormValues(formValues));
        }
        const locationsId = response.data.id;
        onSubmitSuccess(locationsId);
        setFormValues(response.data);
        setInitialFormValues(response.data);
        showToast('Erfolgreich gespeichert', false);
      } catch (error) {
        showToast('Fehler beim Speichern', true);
      } finally {
        setIsLoading(false);
        onModalClose()
        setValidated(false);
      }
    }
  };

  const canWritePayments = userHasPermissionByRight(PermissionsEnum.LocationsPayment, 'write');
  const canWriteContract = userHasPermissionByRight(PermissionsEnum.LocationsContract, 'write');
  const canWriteCosts = userHasPermissionByRight(PermissionsEnum.LocationsCosts, 'write');
  const canWriteRentIncrease = userHasPermissionByRight(PermissionsEnum.LocationsRentIncrease, 'write');
  const canWriteProperty = userHasPermissionByRight(PermissionsEnum.LocationsProperty, 'write');

  const comboButtons: ComboButton[] = useMemo(() => {
    return [
      { id: 'location_edit_overview', label: 'Übersicht', icon: faHouse },
      ...(canWriteContract ? [{ id: 'location_edit_contract', label: 'Vertragliches', icon: faFileContract }] : []),
      ...(canWritePayments ? [{ id: 'location_edit_payment', label: 'Zahlung', icon: faMoneyCheckDollarPen }] : []),
      ...(canWriteCosts ? [{ id: 'location_edit_costs', label: 'Kosten', icon: faMoneyBill }] : []),
      ...(canWriteRentIncrease ? [{ id: 'location_edit_rent_increase', label: 'Mietsteigerung', icon: faMoneyBill }] : []),
    ];
  }, [location, canWritePayments, canWriteContract, canWriteCosts, canWriteRentIncrease, canWriteProperty]);

  function transformFormValues(values: FormValues): FormValues {
    const transformedValues = { ...values };

    if (transformedValues.costsDeposit) {
      transformedValues.costsDeposit = transformedValues.costsDeposit.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsMonthlyNet) {
      transformedValues.costsMonthlyNet = transformedValues.costsMonthlyNet.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsOfTermination) {
      transformedValues.costsOfTermination = transformedValues.costsOfTermination.toString().replace(/,/g, '.')
    }

    if (transformedValues.costsOneTime) {
      transformedValues.costsOneTime = transformedValues.costsOneTime.toString().replace(/,/g, '.')
    }

    if(transformedValues.area) {
      transformedValues.area = Number(transformedValues.area.toString().replace(/,/g, '.')) as any
    }

    return transformedValues;
  }

  const handleMediaSelect = (media: MediaItem | MediaItem[], useCase: string) => {
    const mediaArray = Array.isArray(media) ? media : [media];

    const updateMediaMap = (existingMedia: MediaItem[], newMedia: MediaItem[]) => {
      const mediaMap = new Map(existingMedia.map(item => [item.id, item]));

      newMedia.forEach(m => {
        mediaMap.set(m.id, { ...mediaMap.get(m.id), ...m });
      });

      return Array.from(mediaMap.values());
    };

    switch (useCase) {
      case 'main-image':
        const mainImage = mediaArray[0];
        setFormValues({
          ...formValues,
          mainImage,
          mainImage_id: mainImage.id,
        });
        break;

      case 'contract-media':
        setFormValues({
          ...formValues,
          contractMedia: updateMediaMap(formValues.contractMedia, mediaArray),
        });
        break;

      case 'image-media':
        setFormValues({
          ...formValues,
          imageMedia: updateMediaMap(formValues.imageMedia, mediaArray),
        });
        break;

      default:
        break;
    }
  };

  const handleMediaDelete = (media: MediaItem, useCase: string) => {
    const updateMediaArray = (key: 'contractMedia' | 'imageMedia') => {
      setFormValues({
        ...formValues,
        [key]: formValues[key].filter(item => item.id !== media.id),
      });
    };

    switch (useCase) {
      case 'contract-media':
        updateMediaArray('contractMedia');
        break;

      case 'image-media':
        updateMediaArray('imageMedia');
        break;

      case 'main-image':
        setFormValues({
          ...formValues,
          mainImage_id: null,
          mainImage: null,
        });
        break;

      default:
        break;
    }
  };

  const handleDeleteItem = (message: string, isError: boolean) => {
    showToast(message, isError)
    onModalClose()
    companyNavigate('/locations')
  }

  const handleAddressSelect = (address: {
    street: string;
    streetNumber: string;
    zipcode: string;
    city: string;
    state: string;
    country: string
  }) => {
    setFormValues((prev: any) => ({
      ...prev,
      street: address.street ?? '',
      streetNr: address.streetNumber ?? '',
      zipcode: address.zipcode ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      country: address.country ?? '',
    }));
  };

  const handleAddNewAccount = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=meinNowAccounts')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as MeinNowAccounts

      const currentAccountNumbers = Object.values(details).map(detail => {
        const match = detail.match(/Account (\d+) - \[\d+\]/);
        return match ? parseInt(match[1], 10) : 0;
      });
      const nextAccountNumber = Math.max(...currentAccountNumbers) + 1;

      const newDetails = {
        ...details,
        [newOption]: `Account ${nextAccountNumber} - [${newOption}]`
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );
      setFormValues((prev) => ({
        ...prev,
        accountId: newOption || '',
      }));
      onAccountsUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };

  const handleAddPartner = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=locationsPartners')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as PartnerNames

      const newDetails = {
        ...details,
        [sanitizeInput(newOption)]: newOption
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );
      setFormValues((prev) => ({
        ...prev,
        partner: sanitizeInput(newOption) || '',
      }));
      onPartnerUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };

  const handleAddPaymentMethod = async (newOption: string) => {
    try {
      const res = await ApiClient.get('/settings?ident=paymentMethods')

      const setting = res.data.list[0] as Setting
      const details = (res.data.list[0].details || {}) as PaymentMethods

      const newDetails = {
        ...details,
        [sanitizeInput(newOption)]: newOption
      }

      await ApiClient.put(
        `/settings/${setting?.id}`,
        { details: newDetails }
      );

      setFormValues((prev) => ({
        ...prev,
        costsPaymentMethod: sanitizeInput(newOption) || '',
      }));

      onPaymentMethodsUpdate()
      showToast('Erfolgreich gespeichert', false);
    } catch (error) {
      showToast('Fehler beim Speichern', true);
    }
  };


  const renderAddressSelection = (formValues: FormValues): JSX.Element => {
    return (
      <div className="text-black my-5">
        <h6 className="text-decoration-underline">Ausgewählte Adresse:</h6>
        {isAddressComplete ? (
          <>
            <div>{`${street} ${streetNr}`}</div>
            <div>{`${zipcode} ${city}`}</div>
            <div>{`${getEnumValue(GermanStates, state)}, ${getEnumValue(Countries, country)}`}</div>
          </>
        ) : (
          isMissingAddress ? (
            <p className='text-danger'>Bitte Adresse eingeben!</p>
          ) : (
            <p>Keine Adresse ausgewählt</p>
          )
        )}
        <div className="mt-3">
          {showStreetNr ? (
            <FormGroup<FormValues>
              id="streetNr"
              label="Hausnummer"
              value={formValues.streetNr}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  streetNr: e.target.value,
                }))
              }
            />
          ) : (
            <Form.Check
              className="text-black"
              type="switch"
              id="toggle-streetNr"
              label="Hausnummer manuell anpassen"
              checked={showStreetNr}
              onChange={() => setShowStreetNr(prev => !prev)}
              isInvalid={false}
            />
          )}
        </div>
        <div className="mt-3">
          {showCity ? (
            <FormGroup<FormValues>
              id="city"
              label="Stadt"
              value={formValues.city}
              onChange={(e) =>
                setFormValues((prevValues) => ({
                  ...prevValues,
                  city: e.target.value,
                }))
              }
            />
          ) : (
            <Form.Check
              className="text-black"
              type="switch"
              id="toggle-city"
              label="Stadt manuell anpassen"
              checked={showCity}
              onChange={() => setShowCity(prev => !prev)}
              isInvalid={false}
            />
          )}
        </div>
      </div>
    );
  };


  return (
    <>
      <Modal
        centered
        show
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        fullscreen
      >
        {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
        <Modal.Header>
          <div>
            <Modal.Title>
              {' '}
              <h4>{modalTitle}</h4>
            </Modal.Title>
            {!checkIfDataChanged() ? (
              <span>Keine Änderungen</span>
            ) : (
              <span className="text-danger">Änderungen</span>
            )}
          </div>
          <div className="d-flex">
            <Dropdown className="me-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="btn-soft-primary dropdown-no-arrow round-button p-0"
              >
                <FontAwesomeIcon icon={faEllipsis} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="div" onClick={onModalClose}> <FontAwesomeIcon width={30} icon={faXmark} />Schließen</Dropdown.Item>

                {location && location?.status === 3 && <DeleteModal
                  modalTitle="Auswahl löschen?"
                  buttonName="Löschen"
                  entityIds={[location?.id!]}
                  entityType='locations'
                  onSubmit={handleDeleteItem}
                  onDeleteModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}>
                </DeleteModal>}

                {location && location?.status !== 3 && <ArchiveModal
                  modalTitle="Auswahl archivieren?"
                  buttonName="Archivieren"
                  entityIds={[location?.id!]}
                  entityType='locations'
                  onSubmit={() => onSubmitSuccess(location?.id!)}
                  onArchiveModalChange={setIsTopLevelModalOpen}
                  isDropdownItem={true}
                  isDisabled={!userHasPermissionByRight(PermissionsEnum.Locations, 'delete')}>
                </ArchiveModal>}

              </Dropdown.Menu>
            </Dropdown>
            <Button
              disabled={isLoading}
              variant="primary"
              onClick={() => hiddenSubmitButtonRef.current?.click()}
            >
              Fertig
              {isLoading && (
                <Spinner
                  className="ms-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              )}
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={submit}>
            <>
              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <ComboButtonGroup
                        buttons={comboButtons}
                        selectedCombo={selectedTab}
                        setSelectedCombo={setSelectedTab}
                        borderRadius="normal"
                      ></ComboButtonGroup>
                    </Card.Body>
                  </Card>

                  <Row>
                    <Col>

                      {/* Location Overview Content */}
                      {selectedTab === 'location_edit_overview' && <>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col lg={4}>
                                <h5>Allgemeines</h5>
                              </Col>
                              <Col lg={4}>
                                <FormGroup<FormValues>
                                  id="title"
                                  label="Titel"
                                  value={formValues.title}
                                  onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                                />

                                <AddressAutocomplete
                                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
                                  onAddressSelect={handleAddressSelect}
                                />

                                {renderAddressSelection(formValues)}

                              </Col>
                              <Col lg={4}>
                                <SelectGroup<FormValues>
                                  id="officeType"
                                  label="Büroart"
                                  options={getFieldOptions(fieldConfigs ?? [], 'locations.officeType')}
                                  value={formValues.officeType}
                                  onChange={(e) => setFormValues({ ...formValues, officeType: Number(e.target.value) })}
                                />

                                {partner && <DropdownWithAddOption
                                  id="partner"
                                  label="Partner"
                                  options={Object.entries(partner).map(([value, label]) => ({
                                    value,
                                    label,
                                  }))}
                                  initialValue={formValues.partner.toString()}
                                  onChange={(selectedOption) => {
                                    setFormValues((prev) => ({
                                      ...prev,
                                      partner: selectedOption || '',
                                    }));
                                  }}
                                  onAddOption={handleAddPartner}
                                  placeholder="Auswählen..."
                                />}

                                <Form.Group className="mb-3 w-100 d-flex flex-column">
                                  <Form.Label className='text-black'>Haupt Bild</Form.Label>

                                  {formValues.mainImage && (
                                    <SelectedMediaPreview
                                      selectedMediaForMultiSelect={[formValues.mainImage]}
                                      handleDeleteMedia={(media) => handleMediaDelete(media, 'main-image')}
                                      colProps={12}
                                    />
                                  )}
                                  <div className='d-flex flex-1 w-100 mt-3'>
                                    <Button
                                      className="btn-soft-primary w-100"
                                      onClick={() => {
                                        setShowMainImageSelectionModal(true);
                                      }}
                                    >
                                      {formValues.mainImage ? 'Medium ersetzen' : 'Medium auswählen'}
                                    </Button>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="horizontal-line my-3"></div>

                            <Row>
                              <Col lg={4}><h5>Status</h5></Col>
                              <Col lg={4}>
                                <SelectGroup<FormValues>
                                  id="status"
                                  label="Status des Eintrags"
                                  options={getFieldOptions(fieldConfigs ?? [], 'locations.status')}
                                  value={formValues.status}
                                  onChange={(e) => setFormValues({ ...formValues, status: Number(e.target.value) as any })}
                                />
                                <SelectGroup<FormValues>
                                  id="statusContract"
                                  label="Vertragsstatus"
                                  options={getFieldOptions(fieldConfigs ?? [], 'locations.statusContract')}
                                  value={formValues.statusContract}
                                  onChange={(e) => setFormValues({ ...formValues, statusContract: Number(e.target.value) })}
                                /></Col>
                              <Col lg={4}>
                                <SelectGroup<FormValues>
                                  id="statusCertification"
                                  label="Zertifizierungsstatus"
                                  options={getFieldOptions(fieldConfigs ?? [], 'locations.statusCertification')}
                                  value={formValues.statusCertification}
                                  onChange={(e) => setFormValues({ ...formValues, statusCertification: Number(e.target.value) as any })}
                                />
                              </Col>
                            </Row>

                            {canWriteProperty && <>
                              <div className="horizontal-line my-3"></div>
                              <Row>
                                <Col lg={4}>  <h5>Immobilie</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="area"
                                    label="Fläche"
                                    infoLabel="(in Quadratmeter)"
                                    value={formValues.area ? formValues.area: ''}
                                    onChange={(e) => setFormValues({ ...formValues, area: e.target.value })}
                                  />

                                  <SelectGroup<FormValues>
                                    id="keyType"
                                    label="Schlüssel"
                                    options={getFieldOptions(fieldConfigs ?? [], 'locations.keyType')}
                                    value={formValues.keyType}
                                    onChange={(e) => setFormValues({ ...formValues, keyType: Number(e.target.value) })}
                                  />

                                  <SwitchGroup
                                    label="Feuerlöscher"
                                    value={formValues.fireExtinguisher}
                                    onChange={(e) => setFormValues({ ...formValues, fireExtinguisher: e })}
                                    options={["Ja", "Nein"]}
                                  />

                                  <SwitchGroup
                                    label="Verbandskasten"
                                    value={formValues.firstAidKit}
                                    onChange={(e) => setFormValues({ ...formValues, firstAidKit: e })}
                                    options={["Ja", "Nein"]}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="maxParticipants"
                                    label="Anzahl Teilnehmer"
                                    type='number'
                                    value={formValues.maxParticipants ? formValues.maxParticipants: ''}
                                    onChange={(e) => setFormValues({ ...formValues, maxParticipants: Number(e.target.value) })}
                                  />

                                  <SwitchGroup
                                    label="Offizielle Rettungspläne"
                                    value={formValues.rescuePlan}
                                    onChange={(e) => setFormValues({ ...formValues, rescuePlan: e })}
                                    options={["Ja", "Nein"]}
                                  />

                                  <SwitchGroup
                                    label="Fluchtwegbeschilderung"
                                    value={formValues.escapeRouteSigns}
                                    onChange={(e) => setFormValues({ ...formValues, escapeRouteSigns: e })}
                                    options={["Ja", "Nein"]}
                                  />
                                </Col>
                              </Row>
                            </>
                            }

                            <div className="horizontal-line my-3"></div>

                            <Row>
                              <Col lg={4}>  <h5>Verknüpfungen</h5></Col>
                              <Col lg={4}>
                                <FormGroup<FormValues>
                                  id="googleBusiness"
                                  label="Google Business URL"
                                  value={formValues.googleBusiness}
                                  onChange={(e) => setFormValues({ ...formValues, googleBusiness: e.target.value })}
                                />
                              </Col>
                              <Col lg={4}>
                                {
                                  accounts && <DropdownWithAddOption
                                    id="accountId"
                                    label="Newplan Account"
                                    options={Object.entries(accounts).map(([value, label]) => ({
                                      value,
                                      label,
                                    }))}
                                    initialValue={formValues.accountId.toString()}
                                    onChange={(selectedOption) => {
                                      setFormValues((prev) => ({
                                        ...prev,
                                        accountId: selectedOption || '',
                                      }));
                                    }}
                                    onAddOption={handleAddNewAccount}
                                    placeholder="Auswählen..."
                                  />
                                }
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </>
                      }

                      {/* Location Contract Content */}
                      {selectedTab === 'location_edit_contract' &&
                        <>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col lg={4}> <h5>Allgemein</h5></Col>
                                <Col lg={4}>
                                  <SelectGroup<FormValues>
                                    id="contractRentalStatus"
                                    label="Miet-Status"
                                    options={Object.entries(ActiveInactiveMapping).map(([value, label]) => ({
                                      value,
                                      label,
                                    }))}
                                    value={formValues.contractRentalStatus}
                                    onChange={(e) => setFormValues({ ...formValues, contractRentalStatus: Number(e.target.value) })}
                                  />
                                  <FormGroup<FormValues>
                                    id="contractStart"
                                    label="Mietbeginn"
                                    type='date'
                                    value={formValues.contractStart}
                                    onChange={(e) => setFormValues({ ...formValues, contractStart: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <SwitchGroup
                                    label="Automatische Mietverlängerung"
                                    value={formValues.contractAutoRenewal}
                                    onChange={(e) => setFormValues({ ...formValues, contractAutoRenewal: e })}
                                    options={["Ja", "Nein"]}
                                  />

                                  <FormGroup<FormValues>
                                    id="contractMinRentalPeriod"
                                    label="Mindestmietdauer"
                                    type='number'
                                    infoLabel='(in Monate)'
                                    value={formValues.contractMinRentalPeriod ? formValues.contractMinRentalPeriod: ''}
                                    onChange={(e) => setFormValues({ ...formValues, contractMinRentalPeriod: Number(e.target.value) })}
                                  />

                                </Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}> <h5>Kündigung</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="contractNoticePeriod"
                                    label="Kündigungsfrist"
                                    infoLabel='(in Monate)'
                                    type='number'
                                    value={formValues.contractNoticePeriod ? formValues.contractNoticePeriod: ''}
                                    onChange={(e) => setFormValues({ ...formValues, contractNoticePeriod: Number(e.target.value) })}
                                  />
                                  <FormGroup<FormValues>
                                    id="contractEnd"
                                    label="Vertragsende"
                                    type='date'
                                    value={formValues.contractEnd}
                                    onChange={(e) => setFormValues({ ...formValues, contractEnd: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="contractDateOfTermination"
                                    label="Vertragsende"
                                    type='date'
                                    value={formValues.contractDateOfTermination}
                                    onChange={(e) => setFormValues({ ...formValues, contractDateOfTermination: e.target.value })}
                                  />
                                </Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}> <h5>Ansprechpartner</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="landlordContactPerson"
                                    label="Name"
                                    value={formValues.landlordContactPerson}
                                    onChange={(e) => setFormValues({ ...formValues, landlordContactPerson: e.target.value })}
                                  />
                                  <FormGroup<FormValues>
                                    id="telephone"
                                    label="Festnetz Telefonnummer"
                                    value={formValues.telephone}
                                    onChange={(e) => setFormValues({ ...formValues, telephone: e.target.value })}
                                  />
                                  <FormGroup<FormValues>
                                    id="mobile"
                                    label="Mobile Telefonnummer"
                                    value={formValues.mobile}
                                    onChange={(e) => setFormValues({ ...formValues, mobile: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="email"
                                    label="E-Mail Adresse"
                                    value={formValues.email}
                                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                                  />
                                  <FormGroup<FormValues>
                                    id="fax"
                                    label="Fax"
                                    value={formValues.fax}
                                    onChange={(e) => setFormValues({ ...formValues, fax: e.target.value })}
                                  />
                                </Col>
                              </Row>

                            </Card.Body>
                          </Card>
                        </>
                      }


                      {/* Location Payment Content */}
                      {selectedTab === 'location_edit_payment' &&
                        <>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col lg={4}> <h5>Zahlungsdaten</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="landlordName"
                                    label="Name"
                                    value={formValues.landlordName}
                                    onChange={(e) => setFormValues({ ...formValues, landlordName: e.target.value })}
                                  />
                                  <FormGroup<FormValues>
                                    id="landlordIntendedUse"
                                    label="Verwendungszweck"
                                    value={formValues.landlordIntendedUse}
                                    onChange={(e) => setFormValues({ ...formValues, landlordIntendedUse: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="landlordIban"
                                    label="IBAN"
                                    value={formValues.landlordIban}
                                    onChange={(e) => setFormValues({ ...formValues, landlordIban: e.target.value })}
                                  />
                                  <SwitchGroup
                                    label="Dauerauftrag angelegt"
                                    value={formValues.standingOrder}
                                    onChange={(e) => setFormValues({ ...formValues, standingOrder: e })}
                                    options={["Ja", "Nein"]}
                                  />
                                </Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}> <h5>Zahlungsmodalitäten</h5></Col>
                                <Col lg={4}>
                                  <SelectGroup<FormValues>
                                    id="paymentTime"
                                    label="Zahlungszeitpunkt"
                                    options={getFieldOptions(fieldConfigs ?? [], 'locations.paymentTime')}
                                    value={formValues.paymentTime}
                                    onChange={(e) => setFormValues({ ...formValues, paymentTime: Number(e.target.value) })}
                                  />

                                </Col>
                                <Col lg={4}>

                                  {paymentMethods && <DropdownWithAddOption
                                    id="costsPaymentMethod"
                                    label="Zahlart"
                                    options={Object.entries(paymentMethods).map(([value, label]) => ({
                                      value,
                                      label,
                                    }))}
                                    initialValue={formValues.costsPaymentMethod.toString()}
                                    onChange={(selectedOption) => {
                                      setFormValues((prev) => ({
                                        ...prev,
                                        costsPaymentMethod: selectedOption || '',
                                      }));
                                    }}
                                    onAddOption={handleAddPaymentMethod}
                                    placeholder="Auswählen..."
                                  />}

                                </Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}><h5>Sonstiges</h5></Col>
                                <Col lg={4}>
                                  <SelectGroup<FormValues>
                                    id="paymentDocumentType"
                                    label="Belegart"
                                    options={getFieldOptions(fieldConfigs ?? [], 'locations.paymentDocumentType')}
                                    value={formValues.paymentDocumentType}
                                    onChange={(e) => setFormValues({ ...formValues, paymentDocumentType: Number(e.target.value) })}
                                  />

                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="landlordEmail"
                                    label="E-Mail-Adresse"
                                    value={formValues.landlordEmail}
                                    onChange={(e) => setFormValues({ ...formValues, landlordEmail: e.target.value })}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}></Col>
                                <Col lg={8}>
                                  <FormGroup<FormValues>
                                    id="notesPayment"
                                    label="Notizen zur Zahlung"
                                    type='textarea'
                                    value={formValues.notesPayment}
                                    onChange={(e) => setFormValues({ ...formValues, notesPayment: e.target.value })}
                                  /></Col>
                              </Row>

                            </Card.Body>
                          </Card>
                        </>
                      }

                      {/* Location Costs Content */}
                      {selectedTab === 'location_edit_costs' &&
                        <>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col lg={4}> <h5>Kaution</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="costsDeposit"
                                    label="Kaution"
                                    infoLabel='(in Euro)'
                                    value={formValues.costsDeposit}
                                    onChange={(e) => setFormValues({ ...formValues, costsDeposit: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="dateOfDeposit"
                                    label="Datum Kaution"
                                    type='date'
                                    value={formValues.dateOfDeposit}
                                    onChange={(e) => setFormValues({ ...formValues, dateOfDeposit: e.target.value })}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}></Col>
                                <Col lg={8}>
                                  <FormGroup<FormValues>
                                    id="notesDeposit"
                                    label="Notizen zur Kaution"
                                    type='textarea'
                                    value={formValues.notesDeposit}
                                    onChange={(e) => setFormValues({ ...formValues, notesDeposit: e.target.value })}
                                  /></Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}> <h5>Miete</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="costsMonthlyNet"
                                    label="Miete, monatlich, Netto"
                                    infoLabel='(in Euro)'
                                    value={formValues.costsMonthlyNet}
                                    onChange={(e) => setFormValues({ ...formValues, costsMonthlyNet: e.target.value })}
                                  />
                                  <FormGroup<FormValues>
                                    id="costsOneTime"
                                    label="Einmalige Kosten"
                                    infoLabel='(in Euro)'
                                    value={formValues.costsOneTime}
                                    onChange={(e) => setFormValues({ ...formValues, costsOneTime: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <SwitchGroup
                                    label="Umsatzsteuerpflichtig"
                                    value={formValues.costsTaxDuty}
                                    onChange={(e) => setFormValues({ ...formValues, costsTaxDuty: e })}
                                    options={["Ja", "Nein"]}
                                  />
                                  <FormGroup<FormValues>
                                    id="costsPaymentFirstRent"
                                    label="Bezahlung erste Miete"
                                    type='date'
                                    value={formValues.costsPaymentFirstRent}
                                    onChange={(e) => setFormValues({ ...formValues, costsPaymentFirstRent: e.target.value })}
                                  />
                                </Col>
                              </Row>

                              <div className="horizontal-line my-3"></div>

                              <Row>
                                <Col lg={4}><h5>Kündigung</h5></Col>
                                <Col lg={4}>
                                  <FormGroup<FormValues>
                                    id="costsOfTermination"
                                    label="Kosten bei Kündigung, Netto"
                                    infoLabel='(in Euro)'
                                    value={formValues.costsOfTermination}
                                    onChange={(e) => setFormValues({ ...formValues, costsOfTermination: e.target.value })}
                                  />
                                </Col>
                                <Col lg={4}>
                                  <SwitchGroup
                                    label="Umsatzsteuerpflichtig"
                                    value={formValues.terminationTaxDuty}
                                    onChange={(e) => setFormValues({ ...formValues, terminationTaxDuty: e })}
                                    options={["Ja", "Nein"]}
                                  />
                                </Col>
                              </Row>

                            </Card.Body>
                          </Card>
                        </>
                      }

                      {/* Location Rent Increase Content */}
                      {selectedTab === 'location_edit_rent_increase' &&
                        <>
                          <Row>
                            <Col lg={4}><h5>Mietsteigerung</h5></Col>
                            <Col lg={8}>
                              <RentIncreaseList
                                initialRentIncreases={formValues.rentCostIncreases}
                                onRentIncreasesUpdate={(rentCostIncreases) => setFormValues({ ...formValues, rentCostIncreases })}
                              />
                            </Col>
                          </Row>
                        </>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
            <Button
              type="submit"
              style={{ display: 'none' }}
              ref={hiddenSubmitButtonRef}
            ></Button>
          </Form>
        </Modal.Body >
      </Modal >


      {showMainImageSelectionModal && <MediaPickerModal
        onSelectMedia={(media) => handleMediaSelect(media, 'main-image')}
        initialMedia={formValues.mainImage}
        initialFolderId={location?.folder_id ?? 1}
        allowedMimeTypesSelection={['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml', 'inode/directory']}
        onModalClose={() => setShowMainImageSelectionModal(false)}
      >
      </MediaPickerModal>
      }

      <NotificationToast
        show={show}
        onClose={hideToast}
        message={message}
        error={error}
      />
    </>
  );
};

export default AddEditLocationModal;

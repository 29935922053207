import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SelectWithSearch from '../../form/SelectWithSearch';
import { Location } from '../../../interfaces';

interface CertificateSelectorModalProps {
    location?: Location;
    onClose: () => void;
    onSelectCertificate: (certificateId: number | null) => void;
}

interface SelectOption {
    value: string;
    label: string;
}

/**
 * CertificateSelectorModal Component
 * 
 * This component renders a modal for selecting a certificate, utilizing the `SelectWithSearch` component
 * for searching and selecting a certificate from an API endpoint.
 * 
 * Props:
 * - `location`: An optional `Location` object used to pre-select a certificate if it exists.
 * - `onClose`: A callback function to close the modal.
 * - `onSelectCertificate`: A callback function that receives the selected certificate ID as a number,
 *    or `null` if no selection is made.
 */
const CertificateSelectorModal: React.FC<CertificateSelectorModalProps> = ({
    location,
    onClose,
    onSelectCertificate,
}) => {
    const [selectedCertificate, setSelectedCertificate] = useState<SelectOption | null>(
        location?.certificates_id
            ? { value: location.certificates_id.toString(), label: location.certificate?.title || '' }
            : null
    );

    const handleSelectChange = (id: string, selectedOption: SelectOption | null) => {
        setSelectedCertificate(selectedOption);
    };

    return (
        <Modal show onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Zertifikat auswählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectWithSearch
                    id="certificates_id"
                    label="Zertifikat"
                    placeholder="Suche..."
                    apiEndpoint="/certificates"
                    onChange={handleSelectChange}
                    initialValue={selectedCertificate}
                />


            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-soft-primary" variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button
                    type='button'
                    variant="primary"
                    onClick={() => {
                        onSelectCertificate(selectedCertificate ? Number(selectedCertificate.value) : null);
                        onClose();
                    }}
                >
                    Auswahl bestätigen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CertificateSelectorModal;

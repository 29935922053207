import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import BulkEditModal from '../BulkEditModal';
import DeleteModal from '../DeleteModal';
import ArchiveModal from '../ArchiveModal';
import { usePermissions } from '../../utils/hooks/usePermissions';
import { PermissionsEnum } from '../../utils/enum';
import { FieldConfig } from '../../interfaces';

interface SelectionActionsProps {
  selectedCount: number;
  selectedProducts: any;
  handleDeSelectAll: () => void;
  onSubmitSuccess: (message?: string, isError?: boolean) => void;
  publicationStatus?: number | null
  amountAllItems: number
  fieldConfigs: FieldConfig[]
}

const SelectionActions: React.FC<SelectionActionsProps> = ({ selectedCount,
  selectedProducts,
  handleDeSelectAll,
  onSubmitSuccess,
  publicationStatus,
  amountAllItems,
  fieldConfigs }) => {

  const { userHasPermissionByRight } = usePermissions();
  const [isAllSelected, setIsAllSelected] = useState(false)

  return (
        <Row
          className="d-flex justify-content-between p-3 mx-1 mb-2 bg-light-blue"
        >
          <Col className="d-flex align-items-center" md={4}>
            <span>{isAllSelected ? amountAllItems : selectedCount} Elemente wurden ausgewählt</span>
            <Button variant="link" onClick={handleDeSelectAll}>
              Auswahl aufheben
            </Button>
            {
              !isAllSelected && <Button hidden variant="link" className='ps-0' onClick={() => setIsAllSelected(true)}>
                Alle Auswählen ({amountAllItems})
              </Button>
            }
          </Col>
          <Col className="d-flex justify-content-md-end" md={8}>
            <BulkEditModal
              modalTitle={`${selectedCount} Produkte bearbeiten`}
              buttonName="Ausgewählte Produkte bearbeiten"
              selectedItems={selectedProducts}
              onSubmitSuccess={onSubmitSuccess}
              isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'write')}
              entityType='products'
              fieldConfigs={fieldConfigs}
            ></BulkEditModal>

            {publicationStatus === 3 && <DeleteModal
              modalTitle="Auswahl löschen?"
              buttonName="Löschen"
              entityType='products'
              entityIds={Object.keys(selectedProducts)
                .filter(key => selectedProducts[key] === true)
                .map(Number)}
              onSubmit={onSubmitSuccess}
              isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
            </DeleteModal>}

            {publicationStatus !== 3 && <ArchiveModal
              modalTitle="Auswahl archivieren?"
              buttonName="Archivieren"
              entityType='products'
              entityIds={Object.keys(selectedProducts)
                .filter(key => selectedProducts[key] === true)
                .map(Number)}
              onSubmit={onSubmitSuccess}
              isDisabled={!userHasPermissionByRight(PermissionsEnum.Products, 'delete')}>
            </ArchiveModal>}
          </Col>
        </Row>
  );
};

export default SelectionActions;

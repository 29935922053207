import React, { FormEvent, useRef, useState } from 'react';
import {
    Modal,
    Button,
    Row,
    Col,
    Dropdown,
    Spinner,
    Table,
    Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ApiClient } from '../../../services/ApiClient';
import { Role } from '../../../interfaces';
import NotificationToast from '../../NotificationToast';
import { useToast } from '../../../services/context/ToastContext';
import { useParams } from 'react-router-dom';
import { PermissionsEnum, RoleStatusEnum, getEnumValue, moduleTranslations } from '../../../utils/enum';
import { PermissionRights } from '../../../interfaces';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from '../../DeleteModal';
import useCompanyNavigate from '../../../utils/hooks/useCompanyNavigate';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import Card from '../../bootstrap/card';
import FormGroup from '../../form/FormGroup';
import SelectGroup from '../../form/SelectGroup';


type AddEditRoleModalProps = {
    modalTitle: string;
    role?: Role;
    onSubmitSuccess: (roleId: number) => void;
    onModalClose: () => void;
};

interface FormValues {
    name: string;
    modules: { [key: string]: PermissionRights };
    status: keyof typeof RoleStatusEnum;
    title: string;
}

const AddEditRoleModal: React.FC<AddEditRoleModalProps> = ({
    modalTitle,
    role,
    onSubmitSuccess,
    onModalClose
}) => {
    const { show, message, error, showToast, hideToast } = useToast();
    const { roleId } = useParams<{ roleId?: string }>();
    const [validated, setValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
    const [isTopLevelModalOpen, setIsTopLevelModalOpen] = useState<boolean>(false);
    const companyNavigate = useCompanyNavigate();
    const { userHasPermissionByRight } = usePermissions();
    const [formValues, setFormValues] = useState<FormValues>({
        name: role?.name ?? '',
        modules: role?.modules ?? {},
        status: role?.status ?? 1,
        title: role?.title ?? '',
    });

    const [initialFormValues, setInitialFormValues] = useState<FormValues>(
        JSON.parse(JSON.stringify(formValues))
    );

    const [expandedModules, setExpandedModules] = useState<Record<PermissionsEnum, boolean>>(
        () =>
            Object.values(PermissionsEnum).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {} as Record<PermissionsEnum, boolean>)
    );
    const checkIfDataChanged = (): boolean => JSON.stringify(formValues) !== JSON.stringify(initialFormValues);
    
    const toggleModuleExpansion = (module: PermissionsEnum) => {
        setExpandedModules((prev) => ({
            ...prev,
            [module]: !prev[module],
        }));
    };

    const submit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            try {
                let response;
                if (roleId) {
                    response = await ApiClient.put(`/roles/${roleId}`, formValues);
                } else {
                    response = await ApiClient.post('/roles', formValues);
                }
                const id = response.data.id;
                onSubmitSuccess(id);
                setFormValues(response.data);
                setInitialFormValues(response.data);
                showToast('Erfolgreich gespeichert', false);
            } catch (error) {
                showToast('Fehler beim Speichern', true);
            } finally {
                setIsLoading(false);
                onModalClose();
                setValidated(false);
            }
        }
    };

    const handleModuleSwitchChange = (moduleId: string, right: keyof PermissionRights, checked: boolean) => {
        setFormValues((prev) => {
            const updatedModules = { ...prev.modules };
            updatedModules[moduleId] = updatedModules[moduleId] || { read: false, write: false, delete: false };
            updatedModules[moduleId][right] = checked;

            const { read, write, delete: del } = updatedModules[moduleId];
            if (!read && !write && !del) delete updatedModules[moduleId];

            return { ...prev, modules: updatedModules };
        });
    };

    const renderPermissionsTable = () => {
        const permissions: Array<PermissionsEnum> = Object.values(PermissionsEnum);

        // Identify submodules based on naming pattern
        const getSubmodules = (mainModule: PermissionsEnum) => {
            return permissions.filter(
                (subPermission) => subPermission.startsWith(`${mainModule}.`) && subPermission !== mainModule
            );
        };

        return (
            <Table responsive="md" size="sm">
                <thead>
                    <tr>
                        <th>Module</th>
                        <th style={{ width: '80px' }}>Lesen</th>
                        <th style={{ width: '80px' }}>Schreiben</th>
                        <th style={{ width: '80px' }}>Löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {permissions
                        .filter((permission) => !permission.includes('.'))
                        .map((permission) => {
                            const submodules = getSubmodules(permission);
                            const hasSubmodules = submodules.length > 0;
                            const isExpanded = expandedModules[permission];

                            return (
                                <React.Fragment key={permission}>
                                    <tr>
                                        <td>
                                            {hasSubmodules && (
                                                <FontAwesomeIcon
                                                    icon={isExpanded ? faChevronDown : faChevronRight}
                                                    onClick={() => toggleModuleExpansion(permission)}
                                                    style={{ cursor: 'pointer', marginRight: '8px' }}
                                                />
                                            )}
                                            {getEnumValue(moduleTranslations, permission)}
                                        </td>
                                        <td className='text-center'>
                                            <Form.Check
                                                className="custom-switch"
                                                type="switch"
                                                id={`${permission}_read`}
                                                checked={formValues.modules[permission]?.read || false}
                                                onChange={(e) => handleModuleSwitchChange(permission, 'read', e.target.checked)}
                                            />
                                        </td>
                                        <td className='text-center'>
                                            <Form.Check
                                                className="custom-switch"
                                                type="switch"
                                                id={`${permission}_write`}
                                                checked={formValues.modules[permission]?.write || false}
                                                onChange={(e) => handleModuleSwitchChange(permission, 'write', e.target.checked)}
                                            />
                                        </td>
                                        <td className='text-center'>
                                            <Form.Check
                                                className="custom-switch"
                                                type="switch"
                                                id={`${permission}_delete`}
                                                checked={formValues.modules[permission]?.delete || false}
                                                onChange={(e) => handleModuleSwitchChange(permission, 'delete', e.target.checked)}
                                            />
                                        </td>
                                    </tr>
                                    {hasSubmodules && isExpanded &&
                                        submodules.map((submodule) => (
                                            <tr key={submodule} className="submodule-row">
                                                <td className="">
                                                    <div className="ms-5">{getEnumValue(moduleTranslations, submodule)}</div>
                                                </td>
                                                <td className='text-center'>
                                                    <Form.Check
                                                        className="custom-switch"
                                                        type="switch"
                                                        id={`${submodule}_read`}
                                                        checked={formValues.modules[submodule]?.read || false}
                                                        onChange={(e) => handleModuleSwitchChange(submodule, 'read', e.target.checked)}
                                                    />
                                                </td>
                                                <td className='text-center'>
                                                    <Form.Check
                                                        className="custom-switch"
                                                        type="switch"
                                                        id={`${submodule}_write`}
                                                        checked={formValues.modules[submodule]?.write || false}
                                                        onChange={(e) => handleModuleSwitchChange(submodule, 'write', e.target.checked)}
                                                    />
                                                </td>
                                                <td className='text-center'>
                                                    <Form.Check
                                                        className="custom-switch"
                                                        type="switch"
                                                        id={`${submodule}_delete`}
                                                        checked={formValues.modules[submodule]?.delete || false}
                                                        onChange={(e) => handleModuleSwitchChange(submodule, 'delete', e.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </React.Fragment>
                            );
                        })}
                </tbody>
            </Table>
        );
    };

    const handleDeleteItem = (message: string, isError: boolean) => {
        showToast(message, isError);
        onModalClose();
        companyNavigate('/roles');
    };

    return (
        <>
            <Modal centered show onHide={onModalClose} backdrop="static" keyboard={false} fullscreen>
                {isTopLevelModalOpen && <div className="custom-backdrop"></div>}
                <Modal.Header>
                    <div>
                        <Modal.Title><h4>{modalTitle}</h4></Modal.Title>
                        <span className={!checkIfDataChanged() ? '' : 'text-danger'}>
                            {checkIfDataChanged() ? 'Änderungen' : 'Keine Änderungen'}
                        </span>
                    </div>
                    <div className="d-flex">
                        <Dropdown className="me-2">
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="btn-soft-primary dropdown-no-arrow round-button">
                                <FontAwesomeIcon icon={faEllipsis} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="div" onClick={onModalClose}>
                                    <FontAwesomeIcon width={30} icon={faXmark} />Schließen
                                </Dropdown.Item>
                                {role && (
                                    <DeleteModal
                                        modalTitle="Löschen?"
                                        buttonName="Löschen"
                                        entityIds={[role?.id!]}
                                        entityType='roles'
                                        onSubmit={handleDeleteItem}
                                        onDeleteModalChange={setIsTopLevelModalOpen}
                                        isDropdownItem={true}
                                        isDisabled={!userHasPermissionByRight(PermissionsEnum.Roles, 'delete')}
                                    />
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button disabled={isLoading} variant="primary" onClick={() => hiddenSubmitButtonRef.current?.click()}>
                            Fertig {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={submit}>
                        <Row>
                            <Col lg={6}>
                                <Card className='card-block card-stretch card-height'>
                                    <Card.Body>
                                        <h5>Allgemein</h5>
                                        <FormGroup<FormValues>
                                            id="title"
                                            label="Titel"
                                            value={formValues.title}
                                            onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                                            required
                                        />
                                        <SelectGroup<FormValues>
                                            id="status"
                                            label="Status"
                                            options={Object.entries(RoleStatusEnum).map(([value, label]) => ({ value, label }))}
                                            value={formValues.status}
                                            onChange={(e) => setFormValues({ ...formValues, status: Number(e.target.value) as keyof typeof RoleStatusEnum })}
                                            required
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6}>
                                <Card className='card-block card-stretch card-height'>
                                    <Card.Body>
                                        <h5>Rechte</h5>
                                        {renderPermissionsTable()}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Button type="submit" className='d-none' ref={hiddenSubmitButtonRef} />
                    </Form>
                </Modal.Body>
            </Modal>
            <NotificationToast show={show} onClose={hideToast} message={message} error={error} />
        </>
    );
};

export default AddEditRoleModal;

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  CertificationStatus,
  CertificationStatusEnum,
} from '../utils/enum';
import { getCertificationStatusColor, getIconByCertificationStatus } from '../utils/products/utils';
import PortalWrapper from './PortalWrapper';

interface CertificationStatusDropdownProps {
  statusCertification: CertificationStatus;
  handleCertificationStatusUpdate: (status: CertificationStatus) => void;
  isDisabled?: boolean;
  className?: string;
}

// CertificationStatusDropdown is a dropdown component for managing the certification status of an item.
// It displays the current certification status and allows the user to select a different status from the dropdown.
// The 'handleCertificationStatusUpdate' function is triggered when the user selects a new status.
// The dropdown can be disabled with the 'isDisabled' prop and styled with the 'className' prop.
// 'PortalWrapper' ensures that the dropdown menu is rendered correctly in complex component structures.
const CertificationStatusDropdown: React.FC<CertificationStatusDropdownProps> = ({
  statusCertification,
  handleCertificationStatusUpdate,
  isDisabled,
  className
}) => {
  return (
    <Dropdown className={`ps-2 me-2 rounded ${className || ''}`}>
      <Dropdown.Toggle
        disabled={isDisabled}
        variant="link"
        id="dropdown-certification-basic"
        className={`${getCertificationStatusColor(
          statusCertification
        )} ps-0`}
      >
        {getIconByCertificationStatus(statusCertification)}
        {CertificationStatusEnum[statusCertification]}
      </Dropdown.Toggle>

      <PortalWrapper>
        <Dropdown.Menu>
          {Object.entries(CertificationStatusEnum)
            .filter(([statusKey, _]) => Number(statusKey) !== statusCertification)
            .map(([statusKey, statusValue]) => (
              <Dropdown.Item
                key={statusKey}
                onClick={() =>
                  handleCertificationStatusUpdate(
                    Number(statusKey) as CertificationStatus
                  )
                }
              >
                {getIconByCertificationStatus(Number(statusKey) as CertificationStatus)}
                {statusValue}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </PortalWrapper>
    </Dropdown>
  );
};

export default CertificationStatusDropdown;
